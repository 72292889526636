import React, { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
import { useTeam } from "contexts/TeamContext";
import ArrowRigthIcon from "public/icons/arrow-right.svg";
import CtaButton from "components/atoms/CtaButton";
import Icon from "components/atoms/Icon";
import TrendsIcon from "public/icons/home_trends.svg";
import AnalyticsIcon from "public/icons/home_analytics.svg";
import AiIcon from "public/icons/home_ai.svg";
import SourcingIcon from "public/icons/home_sourcing.svg";
import { usePortal } from "contexts/PortalContext";
import { useSupplier } from "contexts/SupplierContext";
import SyncShopifyConnect from "components/atoms/MediaLibrary/SyncShopifyConnect";
import GlobalIcon from "public/icons/global.svg";
import { sortBySequence } from "utils/sortBySequence";

interface GetStartedProps {
  className?: string;
  onOpenSupplierProfile: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({
  className,
  onOpenSupplierProfile,
}) => {
  const { t } = useTranslation("common");
  const { push, isMaintainer, currentTeam } = useTeam();
  const { isPortal } = usePortal();
  const { isSupplierExists, isSupplierProfileCompleted } = useSupplier();
  const isSupplier = isPortal ? isMaintainer : isSupplierExists;

  const options = [
    {
      image: TrendsIcon,
      title: t("trend_forecasting"),
      desc: t("trend_forecasting_desc"),
      moveToTarget: () => push("/forecast"),
      order: 1,
    },
    {
      image: AnalyticsIcon,
      title: t("analytics"),
      desc: t("analytics_desc"),
      moveToTarget: () => push("/report"),
      order: 2,
    },
    {
      image: AiIcon,
      title: t("ai_design"),
      desc: t("ai_design_desc"),
      moveToTarget: () => push("/ai-design"),
      order: 3,
    },
    {
      image: SourcingIcon,
      title: t("sourcing"),
      desc: t("sourcing_desc"),
      moveToTarget: () => push("/orders"),
      order: 4,
    },
  ];

  const sortedOptions = isSupplier
    ? sortBySequence(options, "order", [4, 3, 1, 2])
    : options;

  return (
    <div className={cn("pb-16", className)}>
      <div className="grid grid-cols-1 xxs:grid-cols-1 2md:!grid-cols-2 gap-4">
        {sortedOptions.map((el) => (
          <div
            key={el.title}
            className="flex justify-between xxs:flex-col xs:flex-row bg-gray-10 rounded-2xl"
          >
            <div className="py-6 pl-6 pr-8 grid grid-cols-1 place-items-start gap-4">
              <h3 className="headline-300">{el.title}</h3>
              <p className="body-100">{el.desc}</p>
              <CtaButton
                iconRight={ArrowRigthIcon}
                iconRightProps={{
                  viewBox: "0 0 16 12",
                  className: "stroke-indigo-70",
                }}
                onClick={el.moveToTarget}
                variant="link"
                className="!font-semibold"
              >
                {t("try_it")}
              </CtaButton>
            </div>
            <Icon
              viewBox="0 0 266 158"
              className="shrink-0 xs:w-[266px] xxs:w-full xxs:h-[158px] xs:h-full"
              component={el.image}
            />
          </div>
        ))}
      </div>
      <div className="mt-8">
        {isSupplier ? (
          <>
            {!isSupplierProfileCompleted && (
              <div className="bg-gray-10 xxs:pl-4 xxs:pr-4 xs:pr-[50px] xs:pl-10 xs:py-6 xxs:py-4 rounded-2xl flex xxs:flex-col xs:flex-row justify-between items-center">
                <div className="flex items-center xxs:flex-col xs:flex-row">
                  <div className="flex items-center justify-center shrink-0 rounded-full w-9 h-9 bg-gray-950">
                    <Icon
                      viewBox="0 0 24 24"
                      component={GlobalIcon}
                      width={24}
                      height={24}
                    />
                  </div>
                  <div className="xxs:ml-0 xs:ml-4 xxs:mt-4 xs:mt-0">
                    <h3 className="headline-300 xxs:text-center xs:text-left mb-2">
                      {t("expand_network")}
                    </h3>
                    <p className="body-100-subtle xxs:text-center xs:text-left">
                      {t("expand_network_desc")}
                    </p>
                  </div>
                </div>
                <CtaButton
                  className="xxs:w-full xs:mt-0 xxs:mt-4 xs:w-auto !py-3 !px-4 whitespace-nowrap max-h-[34px]"
                  onClick={onOpenSupplierProfile}
                  size="sm"
                >
                  {t("complete_profile")}
                </CtaButton>
              </div>
            )}
          </>
        ) : (
          <>
            {currentTeam && !currentTeam?.shopifyConnectIds?.length && (
              <SyncShopifyConnect />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GetStarted;
