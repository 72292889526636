import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import { useQuery } from "react-query";

// components
import Icon from "components/atoms/Icon";

// contenxts
import { useApi } from "contexts/ApiContext";

// types
import { FacetsResponse } from "types";

// icons
import SearchIcon from "public/icons/search_16.svg";
import CloseIcon from "public/icons/close_16.svg";
import { useTeam } from "contexts/TeamContext";
import { debounce } from "lodash";
import { CategoryOptions } from "components/atoms/ExploreTrends/FilterPanel";
import { useUser } from "contexts/UserContext";

interface ForecastSearchProps {
  brand?: string;
  styleOptions?: any[];
  textileOptions?: any[];
  searchKeyword?: string;
  setSearchKeyword?: (value: string) => void;
  setStyleFilter?: (value: string) => void;
  setTextileFilter?: (value: string) => void;
  handleChangeStyleFilter?: (value: any, source: string) => void;
}

const ForecastSearch: React.FC<ForecastSearchProps> = ({
  brand,
  styleOptions,
  textileOptions,
  searchKeyword,
  setSearchKeyword,
  setStyleFilter,
  setTextileFilter,
  handleChangeStyleFilter,
}) => {
  const { global } = useApi();
  const { pushWithState, currentTeam } = useTeam();
  const { user } = useUser();
  const { t } = useTranslation("common");

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(brand || "");

  useEffect(() => {
    if (brand) {
      setKeyword(brand);
    }
  }, [brand]);

  const onSelectValue = useCallback(
    (value: any, source: string) => {
      // UpdateSearchKeyword
      if (source === "search" && setSearchKeyword) {
        setSearchKeyword(value);
      } else if (source === "brand") {
        setSearchKeyword && setSearchKeyword("");
      }

      // UpdateKeyword
      if (brand && source !== "brand") {
        setKeyword(brand);
      } else {
        setKeyword("");
      }

      // UpdateStyleFilter
      if (source === "style" && setStyleFilter) {
        setStyleFilter(value);
      }

      // UpdateTextileFilter
      if (source === "textile" && setTextileFilter) {
        setTextileFilter(value);
      }

      // UpdateStyleFilterForNonSearch
      if (source !== "search" && handleChangeStyleFilter) {
        const filterSource = source === "category" ? "basecat" : source;
        handleChangeStyleFilter(value, filterSource);
      }
    },
    [pushWithState, setStyleFilter, setTextileFilter, brand]
  );

  const onChangeKeyword = useCallback(
    (e: any) => {
      setKeyword(e.target.value);
    },
    [setKeyword]
  );

  const onClickInput = useCallback(() => {
    setIsFocus(true);
  }, [setIsFocus, isFocus]);

  const onBlurInput = debounce(() => {
    setIsFocus(false);
  }, 150);

  const onCancel = useCallback(
    (event: any) => {
      event.stopPropagation();
      if (brand) {
        handleChangeStyleFilter && handleChangeStyleFilter(null, "brand");
      }
      setKeyword("");
      setSearchKeyword && setSearchKeyword("");
    },
    [setKeyword, brand]
  );

  const { data: facetsData } = useQuery<FacetsResponse<any>>(
    ["brands"],
    () => global.query("/facets/bstats/datasources/daily/latest"),
    {
      enabled: !!currentTeam && !!user,
    }
  );

  const defaultConfigureData = useMemo(() => {
    if (!facetsData?.rows || facetsData.rows.length === 0) return [];
    return (
      facetsData?.rows.map(({ brand, sources }) => ({
        name: brand,
        count: sources.m || 0,
      })) || []
    );
  }, [facetsData?.rows]);

  const filteredBrands = useMemo(() => {
    if (keyword.length >= 3) {
      const textiles =
        textileOptions?.map((item) => ({
          name: item.name,
          value: item.value,
          source: "Textile",
        })) || [];

      const styles =
        styleOptions?.map((item) => ({
          name: item.name,
          value: item.value,
          source: "Style",
        })) || [];

      const brands = defaultConfigureData.map((item) => ({
        ...item,
        name: item.name,
        value: item.name,
        source: "Brand",
      }));

      const categories = CategoryOptions.map((item) => ({
        ...item,
        source: "Category",
      }));

      const filteredData = [
        ...categories,
        ...textiles,
        ...styles,
        ...brands,
      ].filter(
        (item) =>
          keyword !== "" &&
          item.name !== "REVOLVE" &&
          item.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
      );

      return filteredData;
    }

    return [];
  }, [defaultConfigureData, keyword, styleOptions, textileOptions]);

  const onKeyDown = (event: any) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      setKeyword(keyword);
      setSearchKeyword && setSearchKeyword(keyword);
    }
  };

  return (
    <div className="relative w-full  max-w-[568px] group">
      <div className={cn("relative w-full")}>
        <input
          onBlur={onBlurInput}
          onClick={onClickInput}
          onKeyDown={onKeyDown}
          value={isFocus ? keyword : brand || keyword}
          onChange={onChangeKeyword}
          className={cn(
            "text-sm placeholder-shown:text-ellipsis leading-[18px] !bg-white placeholder-gray-50 focus:!outline-none focus:!ring-0",
            "w-full py-[18px] pl-5 pr-20 border border-border-normal rounded-[100px]",
            isFocus && "!border-gray-950"
          )}
          placeholder={t("search_style_category_brand")}
          autoComplete="off"
        />
        <button className="!absolute top-2 right-1 flex items-center justify-center h-10 group-focus-within:px-4 !px-3 gap-2 rounded-[100px] bg-gray-950">
          <Icon
            component={SearchIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            className="fill-white"
          />
          {isFocus && (
            <div
              className={cn(
                "group-focus-within:block hidden text-sm leading-tight font-medium text-white"
              )}
            >
              {t("search")}
            </div>
          )}
        </button>

        {(keyword.length > 0 || brand) && (
          <button
            className={cn(
              "p-3 absolute right-[40px] top-2 z-50",
              isFocus && "group-focus-within:!right-[94px]"
            )}
            onClick={onCancel}
          >
            <Icon
              component={CloseIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className="fill-gray-950"
            />
          </button>
        )}
      </div>

      {keyword.length > 0 && (
        <div
          className={cn(
            "absolute hidden top-[68px] w-full rounded-[20px] border border-border-normal bg-white shadow-black/80 overflow-hidden z-50",
            isFocus && "!block"
          )}
        >
          <div className="max-h-[350px] p-6 overflow-y-scroll">
            <div
              className={cn(
                "p-2 w-full flex items-center justify-between hover:bg-gray-20 cursor-pointer"
              )}
              onClick={() => {
                onSelectValue(keyword, "search");
              }}
            >
              <div className="text-gray-950 text-sm leading-normal">
                {t("search_for_styles")} {keyword}
              </div>
              <div className="text-gray-950 text-xs leading-normal">
                {t("search")}
              </div>
            </div>
            {filteredBrands.length > 0 &&
              filteredBrands.map((item) => (
                <div
                  key={item.value}
                  className={cn(
                    "p-2 w-full flex items-center justify-between hover:bg-gray-20 cursor-pointer"
                  )}
                  onClick={() => {
                    onSelectValue(item.value, item.source.toLowerCase());
                  }}
                >
                  <div className="text-gray-950 text-sm leading-normal">
                    {item.name}
                  </div>
                  <div className="text-gray-950 text-xs leading-normal">
                    {t(item.source)}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForecastSearch;
