import React from "react";
import cn from "classnames";
import BackButton from "components/molecules/BackButton";
import useTranslation from "next-translate/useTranslation";
import SupplierFormView from "./SupplierFormView";
import { useState } from "react";
import { SUPPLIER_FORM_TABS } from "utils/constants";
import { useCreateSupplier, useUpdateSupplier } from "hooks/supplier";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Supplier } from "models/Supplier";
import { useSupplier } from "contexts/SupplierContext";
import omit from "lodash/omit";

const SupplierProfileWizard: React.FC<{
  className?: string;
  closeWizard: () => void;
  onComplete: () => void;
}> = ({ className, closeWizard, onComplete }) => {
  const { t } = useTranslation("common");
  const { isSupplierExists, supplier } = useSupplier();
  const [selectedFormTab, setSelectedFormTab] = useState<string>(
    SUPPLIER_FORM_TABS.BASIC
  );
  const [submitedFormIndexes, setSubmitedFormIndexes] = useState<number[]>([]);
  const [values, setValues] = useState<Partial<Supplier>>(
    supplier
      ? omit(supplier, [
          "keywordSetId",
          "updatedat",
          "createdat",
          "owner",
          "status",
        ])
      : {}
  );
  const { onError } = useErrorHandler();

  const isLastStep = selectedFormTab === SUPPLIER_FORM_TABS.FINANCE;

  const { mutate: createSupplier, isLoading: createLoading } =
    useCreateSupplier({
      onError,
      onSuccess() {
        onComplete();
        closeWizard();
      },
    });

  const { mutate: updateSupplier, isLoading: updateLoading } =
    useUpdateSupplier({
      onError,
      onSuccess() {
        onComplete();
        closeWizard();
      },
    });

  const onSubmit = (formValues: any, form?: string) => {
    const mergedValues = { ...values, ...formValues };

    if (isLastStep) {
      if (isSupplierExists) {
        updateSupplier({ id: supplier?.id, ...mergedValues });
      } else {
        createSupplier(mergedValues);
      }
    } else {
      const formIndex = Object.values(SUPPLIER_FORM_TABS).findIndex(
        (el) => el === form
      );
      setSubmitedFormIndexes((s) => {
        if (s.includes(formIndex)) {
          return s;
        }

        return [...s, formIndex];
      });
      setValues(mergedValues);
    }
  };

  const loading = createLoading || updateLoading;

  return (
    <main className={cn("absolute top-0 left-0 z-50", className)}>
      <header className="h-[52px] border-b border-border-normal flex items-center gap-2 px-4">
        <BackButton alt onClick={closeWizard} />
        <h2 className="headline-300">{t("complete_profile")}</h2>
      </header>
      <section
        style={{ height: `calc(100vh - 52px)` }}
        className="w-full overflow-y-auto md:!px-16 xxs:px-4 py-8"
      >
        <SupplierFormView
          submitedFormIndexes={submitedFormIndexes}
          submitLoading={loading}
          onFormChange={setSelectedFormTab}
          onSubmit={onSubmit}
          submitText={isLastStep ? t("submit") : t("next")}
          isStepView
          supplier={
            {
              ...values,
              keywordSet: {
                keywordSet: (values as any).keywordSet,
              },
            } as Supplier
          }
        />
      </section>
    </main>
  );
};

export default SupplierProfileWizard;
