// Function that sorts object specific values by provided sequence
export const sortBySequence = <T>(
  array: T[],
  property: keyof T,
  sequence: any[]
) => {
  const sorted: T[] = [];

  sequence.forEach((order) => {
    const foundItems = array.filter((item) => item[property] === order);
    if (foundItems.length) {
      sorted.push(...foundItems);
    }
  });

  return sorted;
};
