import React, { useState } from "react";
import Layout from "../Layout/Layout";
import useTranslation from "next-translate/useTranslation";
import { useUser } from "contexts/UserContext";
import SupplierProfileWizard from "../Supplier/SupplierProfileWizard";
import { useSupplier } from "contexts/SupplierContext";
import { useQueryClient } from "react-query";
import GetStarted from "components/molecules/Portal/GetStarted";
import ForecastSearch from "components/molecules/ExploreTrends/ForecastSearch";
import { useForecastState } from "contexts/ForecastStateContext";

const getIsUserNameGeneric = (name?: string | null) => {
  if (!name) {
    return true;
  }

  const [userPart, hashPart] = name.split("-");

  return (
    userPart === "user" &&
    hashPart &&
    hashPart.length >= 6 &&
    hashPart.length <= 8
  );
};

const HomeView: React.FC = () => {
  const { t } = useTranslation("common");
  const [isSupplierProfileOpened, setIsSupplierProfileOpened] =
    useState<boolean>(false);
  const { user } = useUser();
  const { refetchSupplier } = useSupplier();
  const {
    brand,
    styleOptions,
    textileOptions,
    searchKeyword,
    setSearchKeyword,
    setStyleFilter,
    setTextileFilter,
    handleChangeStyleFilter,
  } = useForecastState();

  const queryClient = useQueryClient();

  const onCompleteSupplierProfile = async () => {
    await refetchSupplier();
    queryClient.removeQueries("product-orders");
  };

  return (
    <>
      {isSupplierProfileOpened ? (
        <SupplierProfileWizard
          onComplete={onCompleteSupplierProfile}
          closeWizard={() => setIsSupplierProfileOpened(false)}
        />
      ) : (
        <Layout contentClassName="sm:!py-5" className="bg-white">
          <h1 className="headline-100 mb-6">
            {`${t("Hello")}${
              getIsUserNameGeneric(user?.name) ? "" : `, ${user?.name}`
            }`}
          </h1>
          <div className="xxs:max-w-full md:!max-w-[549px] mb-6">
            <ForecastSearch
              brand={brand}
              styleOptions={styleOptions}
              textileOptions={textileOptions}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              setStyleFilter={setStyleFilter}
              setTextileFilter={setTextileFilter}
              handleChangeStyleFilter={handleChangeStyleFilter}
            />
          </div>
          <GetStarted
            onOpenSupplierProfile={() => setIsSupplierProfileOpened(true)}
          />
        </Layout>
      )}
    </>
  );
};

export default HomeView;
